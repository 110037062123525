<template>
    <div v-if="userInfo">
        <van-popup
            class="editUserTelPopup"
            @close="onClose"
            v-model="show"
            :close-on-click-overlay="!!userInfo.telNo"
            get-container="body"
        >
            <div class="title">{{ userInfo.telNo ? '更换手机号' : '绑定手机号' }}</div>
            <div class="form">
                <div class="item">
                    <input v-model.trim="telNo" placeholder="请输入您的手机号" />
                </div>
                <div class="item">
                    <input v-model.trim="code" placeholder="请输入验证码" />
                    <div class="code-btn" :class="hasGetCode ? '' : 'disabled-state'" @click="onCheckTel">
                        {{ hasGetCode ? '获取验证码' : `${timeNum}s` }}
                    </div>
                </div>
            </div>
            <div class="opts">
                <van-button v-if="userInfo.telNo" plain @click="onClose">取消</van-button>
                <van-button class="submit-btn" @click="onSubmit">保存</van-button>
            </div>
        </van-popup>
        <VerificationPopup @success="onSendSuccess" :isShow.sync="isShowVerificationPopup" :telNo="telNo" />
    </div>
</template>
<script>
import VerificationPopup from './VerificationPopup'
import { userTelMod } from '@/api/userApi.js'

let timer = null
const setIntervalTime = 60
export default {
    name: 'editUserTelPopup',
    props: ['isShow', 'userInfo'],
    data() {
        return {
            show: false,
            telNo: '',
            code: '',
            timeNum: setIntervalTime,
            isShowVerificationPopup: false,
            hasGetCode: true,
        }
    },
    components: {
        VerificationPopup,
    },
    watch: {
        isShow: function (isShow) {
            this.show = isShow
            if (isShow) {
                this.telNo = ''
                this.code = ''
                this.hasGetCode = true
            }
        },
    },
    methods: {
        onClose() {
            this.$emit('update:isShow', false)
        },
        onCheckTel() {
            const rule = new RegExp(/^1[0-9]{10}$/)
            if (!rule.test(this.telNo)) {
                return this.$toast('请输入正确的手机号')
            }
            if (!this.hasGetCode) return
            this.isShowVerificationPopup = true
        },
        onSendSuccess() {
            this.hasGetCode = false
            timer = setInterval(() => {
                this.timeNum -= 1
                if (this.timeNum <= 0) {
                    clearInterval(timer)
                    timer = null
                    this.hasGetCode = true
                    this.timeNum = setIntervalTime
                }
            }, 1000)
        },
        onSubmit() {
            var ruleTel = new RegExp(/^1[0-9]{10}$/)
            var ruleCode = new RegExp(/^[0-9]{4,8}$/)
            if (!ruleTel.test(this.telNo)) {
                return this.$toast('请输入正确的手机号')
            } else if (!ruleCode.test(this.code)) {
                return this.$toast('请输入正确的验证码')
            }
            userTelMod({
                userId: this.userInfo.id,
                telNo: this.telNo,
                captcha: this.code,
                token: this.userInfo.token,
                pos: this.$route.name,
            }).then(() => {
                this.show = false
                this.$emit('success', this.telNo)
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.editUserTelPopup {
    width: 360px;
    border-radius: 4px;
    .title {
        font-size: 20px;
        color: #33312f;
        padding: 24px;
        font-weight: 700;
        text-align: center;
    }
    .form {
        .item {
            width: 320px;
            height: 36px;
            background: #ffffff;
            border-radius: 4px;
            border: 1px solid #e6e6e6;
            box-sizing: border-box;
            margin: 0 auto;
            position: relative;
            margin-bottom: 16px;
        }
        input {
            width: 80%;
            height: 24px;
            font-size: 15px;
            color: #33312f;
            line-height: 24px;
            padding: 6px 30px 6px 12px;
        }
        .code-btn {
            width: 96px;
            height: 100%;
            background: $theme-color;
            border-radius: 0px 4px 4px 0px;
            font-size: 14px;
            line-height: 1em;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            right: 0;
            top: 0;
            cursor: pointer;
            color: #ffffff;
        }
        .disabled-state {
            color: #666666;
            background: #eeeeee;
        }
    }
    .opts {
        display: flex;
        justify-content: space-around;
        padding: 16px 32px 24px;
        .van-button {
            width: 100px;
            height: 36px;
            border-radius: 4px;
            font-size: 14px;
            margin: 0 auto;
        }
        .submit-btn {
            border: none;
            background: $theme-color;
            color: #ffffff;
        }
    }
}

@media screen and (max-width: 720px) {
    .editUserTelPopup {
        width: 280px;
        .title {
            font-size: 16px;
            padding: 20px;
        }
        .form {
            .item {
                width: 240px;
                height: 32px;
                margin-bottom: 12px;
            }
            input {
                width: 80%;
                height: 22px;
                font-size: 14px;
                color: #33312f;
                line-height: 24px;
                padding: 4px 30px 4px 12px;
            }
            .code-btn {
                width: 72px;
                font-size: 12px;
            }
        }
        .opts {
            padding: 12px 24px 16px;
            .van-button {
                width: 80px;
                height: 32px;
            }
        }
    }
}
</style>
