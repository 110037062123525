<template>
    <div id="main">
        <router-view></router-view>
        <LoginDialog />
    </div>
</template>
<script>
import LoginDialog from '@/components/global/LoginDialog'

export default {
    name: 'app',
    components: { LoginDialog },
}
</script>
<style lang="scss">
@import '@/scss/tools.scss';
</style>
